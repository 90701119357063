.login-box input {
    font-size: 15px;
    font-weight: 500;
}

.login-box .ant-input {
    height: 35px;
}

.login-form-button {
    text-align: center;
}

.login-form-button .ant-btn {
    border-radius: 50px !important;
    padding: 0px 60px;
}

.login-card .ant-card-body{
    padding : 10px !important;
    background-color: #fff ;
    box-shadow: none;
}

.search-box .ant-input {
    height: 45px;
    margin-right: 10px;
    color: white;
    width: 60%;
    border: none !important;
    background-color: lightgray !important;
    border-radius: 30px !important;
}

.search-box .ant-input:focus {
    border-radius: 30px !important;
}