input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fff;
}

.login-box input:-webkit-autofill,
.login-box input:-webkit-autofill:hover,
.login-box input:-webkit-autofill:focus,
.login-box input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #f5f5f5;
}

.ant-menu-item {
  padding-left: 18px !important;
}

.ant-menu-item-selected {
  padding-left: 18px !important;
}

.ant-menu-item-selected .sidebar-text {
  font-weight: 500;
  color: white;
}

.sidebar-text {
  margin-left: 12px;
  font-weight: 500;
  color: gray;
}



.sidebar-icon {
  color: gray !important;
}

.custom-content {
  position: fixed !important;
  top: 60px !important;
  left: 240px;
  right: 0px !important;
  bottom: 0px !important;
  padding: 50px 0px 24px !important;
  margin: 0px !important;
  overflow: scroll !important;
  background: white !important;
}



.sidebar-open {
  left: 0;
}

/* textarea  */
.custom-textarea {
  border: none;
  border-radius: 9px;
}

.custom-textarea,
.custom-textarea:hover,
.custom-textarea:focus {
  background-color: #fff;
}

.custom-textarea:focus {
  box-shadow: 0 0 0 1.5px rgb(128, 128, 128);
}

/* table  */
.custom-table .ant-table table {
  border-spacing: 0 10px;
}

.custom-table .ant-table-thead>tr>td {
  border: none
}

.custom-row {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* .custom-row td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.custom-row td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
} */

/* card */
.ant-list-split .ant-list-item {
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}

/* modal  */
.ant-modal-content {
  background-color: #f5f5f5 !important;
}

.ant-modal-header {
  background-color: #f5f5f5 !important;
}

/* select */
.ant-select-selector {
  border: none !important;
  box-shadow: none;
}

.ant-switch.ant-switch-checked {
  background-color: green !important;
}

.ant-drawer-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}


/* Media query for small screens (mobile devices) */
@media screen and (max-width: 960px) {
  .logo-center {
    text-align: center;
  }

  .custom-side-bar {
    display: none;
  }

  .custom-content {
    left: 0 !important;
  }
}

/* tutorial */
.tutorial-video-container {
  position: relative;
  padding-bottom: 45%;
  height: 0;
  overflow: hidden;
}

.tutorial-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 0.1px solid #f1f1f1;
  background-color: #f1f1f1;
}

.ant-qrcode {
  width: auto !important;
}

/* step style */

.step-full-width-title .ant-steps-item-title {
  width: 100% !important;
}